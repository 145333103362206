import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"
import Img from "gatsby-image"
import StandingDesk from "../images/developper-desk.svg";
import Lab from '../images/lab.svg'
import Launch from '../images/launch.svg'
import BelleEtBio from '../images/references/belle-et-bio.jpg'
import DietiNatura from '../images/references/dieti-natura.jpg'
import ScandiVie from '../images/references/scandi-vie.jpg'

import LogoBatirMoinsCher from '../images/clients/batirmoinscher.png'
import LogoBelleetbio from '../images/clients/belleetbio.svg'
import LogoDietiNatura from '../images/clients/dieti-natura.png'
import LogoPrintissime from '../images/clients/1001cartes.png'
import LogoScandivie from '../images/clients/scandi-vie.png'
import LogoVoltex from '../images/clients/voltex.gif'
import LogoRugbyshop from '../images/clients/rugbyshop.png'
import LogoVoid from '../images/clients/void.jpg'
import LogoSergeBlanco from '../images/clients/sergeblanco.jpg'

function IndexPage({ data }) {
  return (
    <Layout>
      <SEO
        title="Agence de développements Magento à Toulouse"
        description="Mokadev est une agence de développements Magento basée à Toulouse. Nous vous accompagnons dans vos développements e-commerce sous Magento."
        author="@mokadev"
      />

    <section className="bg-grey-lightest">
        <div className="container m-auto flex flex-col md:flex-row max-w-6xl mx-auto py-8">
            <div className="flex flex-col w-full lg:w-1/2 justify-center items-start px-4">
                <h1 className="text-2xl font-bold inline-block my-8">MOKADEV vous accompagne dans vos développements Magento</h1>
                <p className="mb-4">Magento est au coeur de mes développements depuis la toute première version en 2008, et le reste aujourd'hui encore même si pour certains aspects je suis amené à utiliser GatsbyJS ou Laravel.</p>
            </div>
            <div className="w-full lg:w-1/2 lg:py-6"><img src={StandingDesk} alt="bureau debout" className="w-full"/></div>
        </div>
    </section>

    <section className="text-center py-4">
        <div className="flex flex-wrap justify-between items-start max-w-6xl mx-auto">
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center p-4">
                <img src={Lab} className="m-auto w-24 h-24" alt="Expertise technique" />
                <h2 className="my-4 font-medium">Expertise technique</h2>
                <p className="mb-4">je travaille sur Magento depuis sa sortie en 2008 et suis titulaire de la certification “Magento Certified Developer”. Quels que soient vos besoins fonctionnels, je vous apporte une solution technique.</p>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center p-4">
                <img src={Launch} className="m-auto w-24 h-24" alt="Toujours à l'heure" />
                <h2 className="my-4 font-medium">Toujours à l'heure</h2>
                <p className="mb-4">Tenir les délais et les engagements sur le planning est dans mon ADN. Pas d’effet tunnel, la transparence et la disponibilité sont deux ingrédients essentiels pour la réussite de votre projet.</p>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center p-4">
                <div className="flex items-center text-center text-orange-light">
                    <svg className="w-24 h-24 m-auto fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 24l-4.455-2.572v-12l2.97-1.715v12.001l1.485.902 1.485-.902V7.713l2.971 1.715v12L12 24zM22.391 6v12l-2.969 1.714V7.713L12 3.43 4.574 7.713v12.001L1.609 18V6L12 0l10.391 6z"/>
                    </svg>
                </div>
                <h2 className="my-4 font-medium">Pourquoi Magento?</h2>
                <p className="mb-4">Parce que c’est à jour, la solution la plus aboutie techniquement et fonctionnellement. Poussée par une communauté forte et dynamique, Magento s’adapte à tous types de projets.</p>
            </div>
        </div>
    </section>

    <section className="py-4" id="references">
        <div className="text-center">
            <h1 className="my-4 font-bold text-3xl text-black-lightest">Dernières réalisations</h1>
            <p className="mb-4 max-w-xl m-auto">Quelques une de nos réalisations sous Magento 1 et 2</p>
        </div>

        <div className="my-8 max-w-6xl mx-auto flex flex-col lg:flex-row text-center lg:text-left sm:items-center">
          <div className="w-full lg:w-1/2"><Img fluid={data.dietinatura.childImageSharp.fluid} alt="Refonte de la boutique Dieti Natura"/></div>
          <div className="w-full lg:w-1/2 flex flex-col justify-center items-start p-8 items-center lg:items-start">
            <h2 className="uppercase tracking-wide font-bold">Dieti Natura - Magento 1.9</h2>
            <p className="my-4 font-normal">Refonte de la boutique Dieti Natura</p>
            <p className="mb-4">Client depuis 2013, Dieti Natura est une marque de compléments alimentaires en BIO et non BIO. Après plusieurs années de maintenance quotidienne sur la boutique (Magento 1.4), Dieti Natura a décidé de lancer la refonte graphique et technique. Mokadev à réalisé les développements backend et frontend toujours sous Magento (1.9).</p>
          </div>
        </div>
        <div className="my-8 max-w-6xl mx-auto flex flex-col lg:flex-row text-center lg:text-left sm:items-center">
          <div className="w-full lg:w-1/2 lg:order-last"><Img fluid={data.belleetbio.childImageSharp.fluid} alt="Refonte de la boutique Belle & Bio"/></div>
          <div className="w-full lg:w-1/2 flex flex-col justify-center items-start p-8 items-center lg:items-start">
              <h2 className="uppercase tracking-wide font-bold">Belle & Bio - Magento 2</h2>
              <p className="my-4 font-normal">Refonte de la boutique Belle & Bio</p>
              <p className="mb-4">Client depuis 2013, Belle & Bio est une marque de cosmétiques et de compléments alimentaires BIO. En 2018 elle a repositionné sa gamme de produits et en a profité pour lancer sa refonte graphique et technique. Mokadev à réalisé les développements backend et frontend ainsi que la migration de Magento 1.4 vers Magento 2.</p>
          </div>
        </div>
        <div className="my-8 max-w-6xl mx-auto flex flex-col lg:flex-row text-center lg:text-left sm:items-center">
            <div className="w-full lg:w-1/2"><Img fluid={data.scandivie.childImageSharp.fluid} alt="efonte de la boutique Scandi-vie"/></div>
            <div className="w-full lg:w-1/2 flex flex-col justify-center items-start p-8 items-center lg:items-start">
                <h2 className="uppercase tracking-wide font-bold">Scandi-vie - Magento 1</h2>
                <p className="my-4 font-normal">Refonte de la boutique Scandi-Vie</p>
                <p className="mb-4">Scandi-Vie est revendeur exclusif de poêles et cocottes en fonte Skeppshult, mais propose également d'autres ustensiles et accessoires de cuisine. Pour la refonte de la boutique, Mokadev à réalisé les développements backend et frontend sous Magento 1.9.</p>
            </div>
        </div>
    </section>

    <section className="py-4" id="clients">
        <div className="text-center">
          <h1 className="my-4 font-bold text-3xl text-black-lightest">Clients & projets</h1>
        </div>
        <div className="flex flex-col max-w-6xl mx-auto sm:flex-row flex-wrap my-8">
            <div className="w-full sm:w-1/2 md:w-1/3 h-48 md:h-64 p-8 border-grey-lightest border-r border-b hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoDietiNatura} alt="Dieti Natura"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 h-48 md:h-64 p-8 border-grey-lightest border-r border-b hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoBelleetbio} alt="Belle & Bio" className="w-32 h-32"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center h-48 md:h-64 p-8 border-grey-lightest border-r hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoVoltex} alt="Voltex"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 h-48 md:h-64 p-8 border-grey-lightest border-b hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoScandivie} alt="Scandi Vie"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center h-48 md:h-64 p-8  border-grey-lightest border-r hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoBatirMoinsCher} alt="Bâtir Moins Cher"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center h-48 md:h-64 p-8 border-grey-lightest  hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoPrintissime} alt="1001 Cartes par Printissime"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center h-48 md:h-64 p-8 border-grey-lightest border-r hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoRugbyshop} alt="RugbyShop"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center h-48 md:h-64 p-8  border-grey-lightest border-r hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoVoid} alt="Agence VOID"/></div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col items-center justify-center h-48 md:h-64 p-8 border-grey-lightest  hover:border-0 bg-white hover:transform-scale-subtle transition-normal hover:show-child">
                <div className="w-48 h-48 flex items-center justify-center mx-auto"><img src={LogoSergeBlanco} alt="Serge Blanco"/></div>
            </div>
            
        </div>
    </section>

    <section className="py-4 bg-grey-lightest" id="contact">
      <div className="text-center">
          <h1 className="my-4 font-bold text-3xl text-black-lightest">Contact</h1>
          <p className="mb-4 max-w-lg mx-auto">Discutons de vos besoins et de votre projet.</p>
      </div>
      <div className="text-center">
            <a className="inline-flex items-center border-2 border-red-light px-6 py-2 sm:px-8 sm:py-3 rounded-full text-red-light m-2 font-semibold sm:text-xl no-underline hover:bg-red-light hover:text-white" href="tel:05882952641">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fill-current w-4 h-5 align-middle mx-2">
                    <path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path>
                </svg>
                <span>05 82 95 26 41</span>
            </a>
            <a className="inline-flex items-center border-2 border-red-light px-6 py-2 sm:px-8 sm:py-3 rounded-full text-red-light m-2 font-semibold sm:text-xl no-underline hover:bg-red-light hover:text-white" href="mailto:contact@mokadev.com">
                <svg className="fill-current w-4 h-4 align-middle mx-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1664 1504v-768q-32 36-69 66-268 206-426 338-51 43-83 67t-86.5 48.5-102.5 24.5h-2q-48 0-102.5-24.5t-86.5-48.5-83-67q-158-132-426-338-37-30-69-66v768q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm0-1051v-24.5l-.5-13-3-12.5-5.5-9-9-7.5-14-2.5h-1472q-13 0-22.5 9.5t-9.5 22.5q0 168 147 284 193 152 401 317 6 5 35 29.5t46 37.5 44.5 31.5 50.5 27.5 43 9h2q20 0 43-9t50.5-27.5 44.5-31.5 46-37.5 35-29.5q208-165 401-317 54-43 100.5-115.5t46.5-131.5zm128-37v1088q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-1088q0-66 47-113t113-47h1472q66 0 113 47t47 113z"/></svg>
                <span>contact@mokadev.com</span>
            </a>
      </div>
      <div className="mt-8 px-4 lg:px-0 ">

        <form name="contactForm" action="/succes" method="POST" netlifyhoneypot="bot-field" data-netlify="true" id="contact-form" className="mx-auto md:w-1/2 w-full max-w-lg">
        <p className="hidden">
          <label>Ne pas remplir:</label> <input name="bot-field" />
        </p>
        <input type="hidden" name="form-name" value="contactForm" />
        <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="name"
          >
            Nom
          </label>

          <input
            className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="name"
            name="name"
            placeholder="Nom"
            type="text"
            required="required"
          />

          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="telephone"
          >
            Téléphone
          </label>

          <input
            className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="telephone"
            name="telephone"
            placeholder="Téléphone"
            type="text"
          />

        <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="email"
          >
            E-mail
          </label>

          <input
            className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="email"
            name="email"
            placeholder="E-mail"
            type="email"
            required="required"
          />
          
          <label
            className="block font-bold mb-2 text-xs uppercase"
            htmlFor="message"
          >
            Message
          </label>

          <textarea
            className="appearance-none bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="message"
            name="message"
            placeholder="Votre message..."
            rows="8"
            required="required"
          />

          <button type="submit" className="block bg-red-light hover:bg-white  hover:text-red-light border border-red-light font-bold px-4 py-2 rounded mx-auto text-white">
            Envoyer
          </button>
        </form>
      </div>
    </section>
    </Layout>
  );
}

export const refImage = graphql`
  fragment refImage on File {
    childImageSharp {
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const query = graphql`
  query ImagesQuery {
    dietinatura: file(relativePath: { eq: "references/dieti-natura.jpg" }) {
        ...refImage
    }
  
    belleetbio: file(relativePath: { eq: "references/belle-et-bio.jpg" }) {
    ...refImage
    }
  
    scandivie: file(relativePath: { eq: "references/scandi-vie.jpg" }) {
    ...refImage
    }
  }
`

export default IndexPage;
